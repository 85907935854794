import request from '@/utils/requestDoctor';
import request1 from '@/utils/request';
const baseUrl = 'https://scusapi.shanci.tech';
// 垫付订单列表
function queryBase(query) {
  return request({
    url: baseUrl + '/health-file/queryBase',
    method: 'get',
    params: query,
  });
}
// 查询用户最新体征数据
function queryLatestSignData(query) {
  return request({
    url: baseUrl + '/health-file/queryLatestSignData',
    method: 'get',
    params: query,
  });
}
// 查询医生团队列表
function queryAllHealthHistory(query) {
  return request({
    url: baseUrl + '/health-file/queryAllHealthHistory',
    method: 'get',
    params: query,
  });
}
// /health-file/exportSignData  导出体征数据
// function exportSignData(query) {
//   return request({
//     url: baseUrl + '/health-file/exportSignData',
//     method: 'post',
//     data: query,
//   });
// }

function exportSignData(query) {
  return request1({
    method: 'post',
    url: baseUrl + '/health-file/exportSignData',
    data: query,
    responseType: 'blob',
  });
}

export {
  queryBase,
  queryLatestSignData,
  queryAllHealthHistory,
  exportSignData,
};
